<template>
<section class="news_m">
   <img class="banner" :src="$t('locale')=='zh'?banner:banner_en" alt="" />
   <div class="contain">
      <div
         v-for="value of showNewsList"
         :key="value.id"
         @mouseup="toNewsPage(value.title, value.id)"
         class="news_contain">
         <div class="img_box">
            <img :src="picServeURL + value.image" alt="" />
         </div>
         <article class="news_content">
            <h1>{{ value.title }}</h1>
            <p>{{ value.publish_time }}</p>
         </article>
      </div>

      <div class="more_news" @mouseup="loadMore">
         <div class="mask" v-show="loading"></div>
         <span>{{ loaderText }}</span>
      </div>
   </div>
   <FooterB></FooterB>
</section>
</template>

<script>
import FooterB from "./components/FooterB";
export default {
   components: {
      FooterB,
   },
   data() {
      return {
         showNewsList: null,
         pageLoaded: 1,
         loading: false,
         loaderState: ["", "加载更多", "加载中...", "已经是全部了"],
         loaderText: "加载更多",
         picServeURL: Global.Production,
         banner: require('../../assets/zh/images-mobile/news_banner@2x.png'),
         banner_en: require('../../assets/en/images-mobile/news_banner@2x.png'),
      };
   },
   created() {
      this.getNews(this.pageLoaded).then((res) => {
         this.showNewsList = res.data.results;
      });
   },
   methods: {
      loadMore() {
         if (this.loaderText == "已经是全部了") return;
         this.loading = true;
         this.loaderText = this.loaderState[2];
         this.pageLoaded += 1;
         this.getNews(this.pageLoaded).then((res) => {
            this.loading = false;
            if (res.data.results) {
               this.loaderText = this.loaderState[1];
               this.showNewsList = this.showNewsList.concat(res.data.results);
            } else {
               this.loaderText = this.loaderState[3];
            }
         });
      },
      getNews(num) {
         return this.axios.get(Global.NEWS_PAGE_API + `${num}`);
      },
      toNewsPage(title, id) {
         this.$router.push({
            name: "NewsPage",
            params: {
               id,
            },
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.news_m {
   margin-top: 48px;
   word-break: break-word !important;

   .banner {
      width: 100%;
      height: 150px;
   }

   .contain {
      // height: 842px;
      box-sizing: border-box;
      padding: 20px;
      background-color: #f5f5f5;

      div.news_contain {
         display: flex;
         padding-bottom: 12px;
         justify-content: space-between;

         .img_box {
            width: 105px;
            height: 82px;

            img {
               object-fit: cover;
               width: 105px;
               height: 82px;
            }
         }

         article.news_content {
            h1 {
               font-size: 14px;
               color: #333;
               line-height: 20px;
               width: 218px;
               white-space: normal;
               overflow: hidden;
               text-overflow: ellipsis;
               -webkit-line-clamp: 2;
               -webkit-box-orient: vertical;
               display: -webkit-box;
               margin: 8px 0;
            }

            p {
               font-size: 12px;
               font-family:   PingFangSC-Regular, PingFang SC;
               color: #333;
               line-height: 10px;
            }
         }
      }
   }

   .more_news {
      width: 104px;
      height: 30px;
      border-radius: 16px;
      border: 1px solid #999999;
      margin: 8px auto 12px;
      position: relative;
      overflow: hidden;

      span {
         display: inline-block;
         white-space: nowrap;
         font-size: 14px;
         font-family:   PingFangSC-Regular, PingFang SC;
         font-weight: 400;
         color: #666666;
         width: 100%;
         line-height: 30px;
         text-align: center;
         vertical-align: top;
      }

      div.mask {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-color: rgba($color: #fff, $alpha: 0.7);
      }
   }
}

.demo-loadmore-wrap {
   width: 100%;
   max-width: 360px;
   height: 420px;
   display: flex;
   flex-direction: column;

   .mu-appbar {
      width: 100%;
   }
}

.demo-loadmore-content {
   flex: 1;
   overflow: auto;
   -webkit-overflow-scrolling: touch;
}

.demo-loadmore-wrap {
   width: 100%;
   display: flex;
   flex-direction: column;

   .mu-appbar {
      width: 100%;
   }
}

.demo-loadmore-content {
   flex: 1;
   overflow: auto;
   -webkit-overflow-scrolling: touch;
}
</style>
